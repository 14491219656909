// Styles
import * as S from './styles';

const Item = ({
  path,
  title,
  color,
  handleClose,
  inModal,
}: {
  path: string;
  title: string;
  color: string;
  handleClose: () => void;
  inModal: boolean;
}) => {
  return (
    <S.Item inModal={inModal}>
      <S.Link
        to={path}
        activeClassName='selected'
        onClick={handleClose}
        inModal={inModal}>
        {title}
        <S.Overlay inModal={inModal} style={{ background: color }} />
      </S.Link>
    </S.Item>
  );
};

export default Item;
