import { useSpring } from 'react-spring';

// Icons
import { ReactComponent as CloseIcon } from 'icons/close.svg';

// Styles
import * as S from './styles';

const Modal = ({
  handleClose,
  show,
  children,
}: {
  handleClose: () => void;
  show: boolean;
  children: (JSX.Element | null)[];
}) => {
  const config = { mass: 5, tension: 2000, friction: 200 };

  const slide = useSpring({
    config,
    from: { transform: 'translateY(-100%)', opacity: 0 },
    to: {
      transform: show ? 'translateY(0)' : 'translateY(-100%)',
      opacity: show ? 1 : 0,
    },
  });

  return (
    <>
      <S.Container style={slide}>
        <S.Close
          href='void(0)'
          onClick={handleClose}
          aria-label='Fermeture Menu'>
          <CloseIcon />
        </S.Close>
        <S.Items>{children}</S.Items>
      </S.Container>
      {show && <S.Overlay onClick={handleClose} />}
    </>
  );
};

export default Modal;
