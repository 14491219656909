import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Styles
import * as S from './styles';

export const LeftArrow = ({
  onClick,
}: {
  onClick: (event: React.MouseEvent<HTMLAnchorElement>) => void;
}) => {
  return (
    <S.LeftArrow href='void(0)' aria-label='Left Arrow' onClick={onClick}>
      <FontAwesomeIcon icon='angle-left' size='2x' />
    </S.LeftArrow>
  );
};

export const RightArrow = ({
  onClick,
}: {
  onClick: (event: React.MouseEvent<HTMLAnchorElement>) => void;
}) => {
  return (
    <S.RightArrow href='void(0)' aria-label='Right Arrow' onClick={onClick}>
      <FontAwesomeIcon icon='angle-right' size='2x' />
    </S.RightArrow>
  );
};
