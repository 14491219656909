import { useState } from 'react';
import { Route } from 'react-router-dom';

// Components
import Header from 'components/Header';
import Item from 'components/Item';
import Modal from 'components/Modal';

// Custom hooks
import useWitdh from 'hooks/useWidth';

// Data
import items from 'data/items';

// Styles
import * as S from './styles';

const App = () => {
  const [show, setShow] = useState<boolean>(false);

  const width = useWitdh();

  const isSmall = width < 1000;

  const showModal = (event?: React.SyntheticEvent<HTMLAnchorElement>) => {
    if (event) {
      event.preventDefault();
    }

    setShow(true);
  };

  const hideModal = (event?: React.SyntheticEvent<HTMLAnchorElement>) => {
    if (event) {
      event.preventDefault();
    }

    setShow(false);
  };

  const links = items.map((item, i) => {
    if (i === 0 || i === 4 || i === 5) {
      return null;
    }

    return (
      <Item
        key={i}
        path={item.path}
        title={item.title}
        color={item.color}
        handleClose={() => {
          if (!show) {
            return;
          }

          hideModal();
        }}
        inModal={isSmall}
      />
    );
  });

  return (
    <>
      <S.GlobalStyle />
      <Header items={items} links={links} showModal={showModal} />
      {items.map((route, index) => (
        <Route
          key={index}
          path={route.path}
          exact={route.exact}
          component={route.component}
        />
      ))}
      <Modal show={show} handleClose={hideModal} children={links} />
    </>
  );
};

export default App;
