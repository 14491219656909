import styled from 'styled-components';

export const Section = styled.section`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media not all and (min-resolution: 0.001dpcm) {
    @media (min-width: 1000px) {
      height: 100vh;
    }
  }
`;

// export const Maintenance = styled.h1`
//   margin: auto;
//   font-size: 30px;
//   color: rgb(255, 90, 80);

//   @media (min-width: 768px) {
//     font-size: 40px;
//   }
// `;
