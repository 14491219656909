// Styles
import * as S from './styles';

const Indicator = ({
  index,
  activeIndex,
  onClick,
}: {
  index: number;
  activeIndex: number;
  onClick: (event: React.MouseEvent<HTMLAnchorElement>) => void;
}) => {
  return (
    <li>
      <S.Indicator
        href='void(0)'
        onClick={onClick}
        active={index === activeIndex}>
        <p>Hidden Text</p>
      </S.Indicator>
    </li>
  );
};

export default Indicator;
