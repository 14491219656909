// Images
import firstS from 'images/first-slide-small.jpg';
import firstM from 'images/first-slide-medium.jpg';
import firstL from 'images/first-slide-large.jpg';
import secondS from 'images/second-slide-small.jpg';
import secondM from 'images/second-slide-medium.jpg';
import secondL from 'images/second-slide-large.jpg';
import thirdS from 'images/third-slide-small.jpg';
import thirdM from 'images/third-slide-medium.jpg';
import thirdL from 'images/third-slide-large.jpg';

export type Slide = {
  path: string;
  order: string;
  images: string[];
};

const slides: Slide[] = [
  {
    path: '/psychomotricite',
    order: 'first',
    images: [firstS, firstM, firstL],
  },
  {
    path: '/moi',
    order: 'second',
    images: [secondS, secondM, secondL],
  },
  {
    path: '/recherches',
    order: 'third',
    images: [thirdS, thirdM, thirdL],
  },
];

export default slides;
