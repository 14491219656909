import { useEffect } from 'react';

// Components
import Footer from 'components/Footer';
import Separator from 'components/Separator';

// Images
import first from 'images/first-psychomotricite.jpg';
import second from 'images/second-psychomotricite.jpg';
import third from 'images/third-psychomotricite.jpg';

// Styles
import * as S from './styles';

const Psycho = ({ color }: { color: string }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <S.Section>
      <S.Container>
        <h2>PSYCHOMOTRICITE</h2>
        <Separator />
        <S.First>
          <div style={{ maxWidth: '700px' }}>
            <p>
              La psychomotricité a pour but d’observer et d'analyser le
              développement global de la personne à tous les âges de la vie.
            </p>
            <p>
              Elle étudie l’ensemble des comportements moteurs mis en lien avec
              l’activité cérébrale, la vie psychique, affective et relationnelle
              du sujet. L’approche est donc globale et vise une harmonie entre
              les fonctions motrices, psychiques et affectives du patient.
            </p>
          </div>
          <S.Trinite />
        </S.First>
        <S.Second>
          <h3>Déroulement du bilan psychomoteur</h3>
          <div style={{ maxWidth: '700px', width: '100%' }}>
            <S.Player
              url='https://www.youtube.com/watch?v=oFxKj2o_x8I'
              controls
            />
            <i>
              Remarque: Le bilan et la prise en soin psychomotrice ne sont pas
              remboursés par la sécurité sociale pour autant en tant
              qu'auxiliaire de médecine, le psychomotricien exerce sur
              prescription médicale.
            </i>
          </div>
        </S.Second>
        <S.Third>
          <b>Comment le psychomotricien travaille t-il ?</b>
          <div>
            <p>
              En fonction des résultats aux différents tests et/ou épreuves et
              des observations réalisées lors du bilan psychomoteur, le
              psychomotricien définit les besoins d’une prise en soin. Et avec
              l’accord du patient et auquel cas de l’entourage, le
              psychomotricien enclenche un suivi.
            </p>
            <p>
              Le psychomotricien utilisera alors des médiations corporelles
              diverses. Celles-ci seront choisies en fonction de la personne, de
              ses affinités et de ses difficultés.
            </p>
          </div>
        </S.Third>
        <S.Fourth>
          <S.Image src={first} alt='First Child Patient' />
          <S.Image src={second} alt='Second Child Patient' />
          <S.Image src={third} alt='Third Child Patient' />
        </S.Fourth>
      </S.Container>
      <Footer color={color} />
    </S.Section>
  );
};

export default Psycho;
