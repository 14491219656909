import { useEffect } from 'react';

// Components
import Footer from 'components/Footer';
import Separator from 'components/Separator';

// Data
import sports from 'data/sports';

// Styles
import * as S from './styles';

const Docs = ({ type, color }: { type: string; color: string }) => {
  return (
    <S.Documents>
      {sports[type].map((sport, index) => (
        <S.Button
          key={index}
          href={sport.path}
          target='_blank'
          rel='noopener noreferrer'
          download>
          <div className='research-container__doc--top'>
            <S.Doc />
            <S.Text>{sport.author}</S.Text>
            <S.Text>{sport.title}</S.Text>
          </div>
          <S.Span style={{ color }}>
            LIRE
            <S.Icon icon='angle-right' size='1x' />
          </S.Span>
        </S.Button>
      ))}
    </S.Documents>
  );
};

const Sport = ({ color }: { color: string }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <S.Section>
      <S.Container>
        <h2>Sport & Psychomot'</h2>
        <Separator />
        <h3>Football</h3>
        <Docs type='football' color={color} />
        <h3>CrossFit</h3>
        <Docs type='crossfit' color={color} />
      </S.Container>
      <Footer color={color} />
    </S.Section>
  );
};

export default Sport;
