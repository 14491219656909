import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { animated } from 'react-spring';

interface Props {
  active: boolean;
}

export const Slide = styled.li<Props>`
  display: none;
  flex-direction: column;
  max-width: 500px;
  list-style-type: none;
  display: ${(props) => props.active && 'flex'};

  @media (min-width: 1000px) {
    flex-direction: row;
    justify-content: space-between;
    max-width: 100%;
  }
`;

export const LeftContainer = styled(animated.div)`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 768px) {
    max-width: 500px;
  }
`;

export const H1 = styled.h1`
  font-size: 30px;
  font-weight: 600;
  text-align: center;

  @media (min-width: 768px) {
    font-size: 40px;
  }

  @media (min-width: 1000px) {
    align-self: flex-start;
  }

  @media (min-width: 1200px) {
    font-size: 50px;
  }
`;

export const Text = styled.p`
  font-size: 16px;
  font-weight: 300;
  text-align: left;
  margin-bottom: 20px;
  color: #444;
  line-height: 25px;

  @media (min-width: 1000px) {
    align-self: flex-start;
  }
`;

export const Author = styled.p`
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 20px;
  color: #888;

  @media (min-width: 1000px) {
    align-self: flex-start;
  }
`;

export const More = styled(Link)`
  align-self: center;
  text-decoration: none;

  @media (min-width: 1000px) {
    align-self: flex-start;
  }
`;

export const Span = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  font-weight: 600;
`;

export const Icon = styled(FontAwesomeIcon)`
  margin-left: 5px;
  margin-top: 1px;
`;

export const RightContainer = styled(animated.div)`
  display: none;
  flex-direction: column;
  align-items: center;

  @media (min-width: 768px) {
    display: flex;
  }
`;

export const Picture = styled.picture`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 768px) {
    display: flex;
  }
`;

export const Image = styled.img`
  width: 80%;
  max-width: 300px;
  margin-top: 50px;
  border-style: solid;
  border-width: 2px;
  border-color: white;
  border-radius: 4px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.3);

  @media (min-width: 1000px) {
    width: 400px;
    max-width: 400px;
    margin-top: 8px;
  }

  @media (min-width: 1200px) {
    width: 500px;
    max-width: 500px;
    max-height: 375px;
    margin-top: 10px;
  }
`;
