import { useEffect } from 'react';

// Components
import Footer from 'components/Footer';
import Separator from 'components/Separator';

// Images
import image from 'images/sept-regles-psychomotricite.jpg';

// Styles
import * as S from './styles';

const SINGER_URL = 'https://www.youtube.com/user/trrsmith85';
const FBPAGE_URL = 'https://www.facebook.com/Coeur-Dartiflo-122137892517830/';
const RULES_URL =
  'https://f92761c0-bd01-421c-8bb0-7f7a7d851952.filesusr.com/ugd/41305c_f2415ade48d344a79373aab10ca2cbc6.pdf';
const COCO_URL = 'https://www.cocovirus.net/';

const Covid = ({ color }: { color: string }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <S.Section>
      <S.Container>
        <h2>COVID-19</h2>
        <Separator />
        <p>
          Enquête « Psychomotriciens en EHPAD dans le contexte épidémique de la
          Covid » - Mars/Septembre 2020
          <br />
          Elodie Cottel et Laura Dias Da Silva
        </p>
        <div style={{ marginBottom: '20px' }}>
          <S.Anchor
            href='/medias/enquete_ehpad_covid.pdf'
            rel='noopener noreferrer'
            target='_blank'>
            Résultats détaillés de l'enquête par questionnaire
          </S.Anchor>{' '}
          -{' '}
          <S.Anchor
            href='/medias/synthese_enquete.pdf'
            rel='noopener noreferrer'
            target='_blank'>
            Synthèse
          </S.Anchor>
        </div>
        <p>
          Je vous recommande <b>la chanson des petits gestes</b>, une vidéo de{' '}
          <S.Link href={SINGER_URL} rel='noopener noreferrer' target='_blank'>
            Thomas Smith & Caroline Sattler
          </S.Link>{' '}
          basée des illustrations de{' '}
          <S.Link href={FBPAGE_URL} rel='noopener noreferrer' target='_blank'>
            Coeur d’artiflo
          </S.Link>
          .
        </p>
        <div style={{ maxWidth: '700px', width: '100%', alignSelf: 'center' }}>
          <S.Player
            url='https://www.youtube.com/watch?v=S-qo1ELpk38'
            controls
          />
        </div>
        <p>
          Merci à{' '}
          <S.Link href={COCO_URL} rel='noopener noreferrer' target='_blank'>
            Coco Virus
          </S.Link>
          , une initiative bénévole destinée à combattre l'impact de la Crise du
          Coronavirus sur l'équilibre des plus jeunes, pour leur super affiche
          nous concernant. Par ailleurs, vous retrouverez sur leur site
          plusieurs BD illustratives sur les difficultés de la situation
          actuelle.
        </p>
        <a
          href={RULES_URL}
          rel='noopener noreferrer'
          target='_blank'
          style={{ alignSelf: 'center' }}>
          <S.Image
            alt="Les 7 règles d'or pour ma séance de psychomotricité"
            src={image}
          />
        </a>
      </S.Container>
      <Footer color={color} />
    </S.Section>
  );
};

export default Covid;
