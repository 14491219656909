// Icons
import { ReactComponent as MailIcon } from 'icons/mail.svg';
import { ReactComponent as FacebookIcon } from 'icons/facebook.svg';
import { ReactComponent as LinkedinIcon } from 'icons/linkedin.svg';
import { ReactComponent as YoutubeIcon } from 'icons/youtube.svg';
import { ReactComponent as InstagramIcon } from 'icons/instagram.svg';
import { ReactComponent as TwitchIcon } from 'icons/twitch.svg';

// Styles
import * as S from './styles';

const Contact = () => {
  const facebookUrl = 'https://www.facebook.com/ALLO-Psychomot-106751574162780';
  const linkedinUrl =
    'https://www.linkedin.com/in/laura-dias-da-silva-174662140';
  const youtubeUrl = 'https://www.youtube.com/channel/UC5_yBU7tNtTlgfluB9hielw';
  const instagramUrl = 'https://www.instagram.com/allopsychomot';
  const twitchUrl = 'https://www.twitch.tv/allopsychomot';

  return (
    <S.Contact>
      <S.List>
        <S.ListItem>
          <S.Button
            href='mailto:ldias-da-silva@psychomot.com'
            aria-label='Email'>
            <S.Icon>
              <MailIcon />
            </S.Icon>
            <p>Ldias-da-silva@psychomot.com</p>
          </S.Button>
        </S.ListItem>
        <S.ListItem>
          <S.Row>
            <S.Button
              href={facebookUrl}
              aria-label='Facebook'
              rel='noopener noreferrer'
              target='_blank'>
              <FacebookIcon
                height={26}
                style={{ color: 'white', marginRight: '40px' }}
              />
            </S.Button>
            <S.Button
              href={instagramUrl}
              aria-label='Instagram'
              rel='noopener noreferrer'
              target='_blank'>
              <InstagramIcon
                height={26}
                style={{ color: 'white', marginRight: '40px' }}
              />
            </S.Button>
            <S.Button
              href={twitchUrl}
              aria-label='Twitch'
              rel='noopener noreferrer'
              target='_blank'>
              <TwitchIcon
                height={26}
                style={{ color: 'white', marginRight: '40px' }}
              />
            </S.Button>
            <S.Button
              href={linkedinUrl}
              aria-label='LinkedIn'
              rel='noopener noreferrer'
              target='_blank'>
              <LinkedinIcon
                height={26}
                style={{ color: 'white', marginRight: '40px' }}
              />
            </S.Button>
            <S.Button
              href={youtubeUrl}
              aria-label='Youtube'
              rel='noopener noreferrer'
              target='_blank'>
              <YoutubeIcon height={26} style={{ color: 'white' }} />
            </S.Button>
          </S.Row>
        </S.ListItem>
      </S.List>
    </S.Contact>
  );
};

export default Contact;
