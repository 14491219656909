import { BrowserRouter } from 'react-router-dom';

// Components
import App from 'components/App';

const Root = () => {
  return (
    <BrowserRouter>
      <App />
    </BrowserRouter>
  );
};

export default Root;
