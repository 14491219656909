import styled from 'styled-components';

interface Props {
  active: boolean;
}

export const Indicator = styled.a<Props>`
  display: block;
  width: 30px;
  height: 8px;
  border-radius: 4px;
  background-color: rgb(255, 90, 80);
  cursor: pointer;
  transition: opacity 0.15s cubic-bezier(0.4, 0, 1, 1);
  opacity: ${(props) => (props.active ? '0.9' : '0.15')};

  &:hover {
    opacity: 0.5;
  }

  p {
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
  }
`;
