import { useEffect } from 'react';

// Components
import Footer from 'components/Footer';
import Separator from 'components/Separator';

// Video
import Video from 'images/prix_de_la_vocation.mp4';

// Images
import cloudS from 'images/cloud-small.jpg';
import cloudL from 'images/cloud-large.jpg';
import frpmc from 'images/frpmc.png';
import mbb from 'images/mbb.png';

// Styles
import * as S from './styles';

const Me = ({ color }: { color: string }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <S.Section>
      <S.Container>
        <h2>QUI SUIS-JE ?</h2>
        <Separator />
        <S.First>
          <div style={{ maxWidth: '700px', width: '100%' }}>
            <S.Citation>
              "Je suis psychomotricienne diplômée d'état depuis 2015. Je vis
              psychomotricité. J'apprends et découvre jour après jour en
              pratiquant, au travers d'échanges et de rencontres, en explorant,
              lisant. Je transmets. Mon métier, c'est ma passion. Ma vocation."
            </S.Citation>
          </div>
        </S.First>
        <S.Second>
          <div style={{ maxWidth: '700px', width: '100%' }}>
            <p>
              Durant mon parcours, j'ai eu la chance d'obtenir le soutien de la{' '}
              <S.Link
                href='http://frpmc.fr/'
                rel='noopener noreferrer'
                target='_blank'>
                Fondation pour la Recherche en Psychomotricité et Maladies de
                Civilisation
              </S.Link>{' '}
              et de la{' '}
              <S.Link
                href='https://fondationdelavocation.org/'
                rel='noopener noreferrer'
                target='_blank'>
                Fondation Marcel Bleustein-Blanchet de la vocation
              </S.Link>
              .
            </p>
            <S.Fondations>
              <S.Fondation
                src={frpmc}
                alt='Fondation pour la Recherche en Psychomotricité et Maladies de Civilisation'
              />
              <S.Fondation
                src={mbb}
                alt='Fondation Marcel Bleustein-Blanchet de la vocation'
              />
            </S.Fondations>
          </div>
        </S.Second>
        <div style={{ maxWidth: '700px', width: '100%', alignSelf: 'center' }}>
          <S.Player controls>
            <source src={Video} type='video/mp4' />
          </S.Player>
        </div>
        <S.Picture>
          <source
            media='(min-width: 768px)'
            type='image/jpeg'
            srcSet={cloudL}
          />
          <img src={cloudS} alt='Nuage Psychomotricité' />
        </S.Picture>
      </S.Container>
      <Footer color={color} />
    </S.Section>
  );
};

export default Me;
