import styled from 'styled-components';

export const Contact = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 260px;
  margin-bottom: 50px;

  @media (min-width: 768px) {
    margin-bottom: unset;
  }
`;

export const List = styled.ul`
  list-style: none;
`;

export const ListItem = styled.li``;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const Button = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
  text-decoration: none;

  ${ListItem}:last-child & {
    margin-bottom: 0px;
  }
`;

export const Icon = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  margin-right: 10px;
`;
