// Components
import Contact from 'components/Contact';
import Credit from 'components/Credit';

// Icons
import { ReactComponent as Div } from 'icons/div.svg';

// Styles
import * as S from './styles';

const Footer = ({ color }: { color: string }) => {
  return (
    <S.Footer>
      <S.Background>
        <S.Color style={{ background: color }} />
        <S.Path>
          <Div />
        </S.Path>
      </S.Background>
      <S.Foreground>
        <S.Container>
          <Contact />
          <Credit />
        </S.Container>
        {/* <Credit tablet /> */}
      </S.Foreground>
    </S.Footer>
  );
};

export default Footer;
