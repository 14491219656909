import styled from 'styled-components';
import { animated } from 'react-spring';

export const Overlay = styled(animated.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
`;

export const Container = styled(animated.section)`
  position: fixed;
  background: white;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 1px;
  z-index: 100;
`;

export const Close = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  width: 200px;
`;

export const Items = styled.ul`
  list-style: none;
  height: calc(60 * 5) px;
`;
