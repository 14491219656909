export type Sport = {
  path: string;
  author: string;
  title: string;
};

const sports: { [type: string]: Sport[] } = {
  football: [
    {
      path: 'https://psychomot-media.s3.eu-west-3.amazonaws.com/Place+du+psychomotricien+aupres+de+jeunes+joueurs+issus+d%E2%80%99un+centre+de+formation.pdf',
      author: 'Laviale, L. (2021)',
      title:
        'Football et Psychomotricité : Place du psychomotricien auprès de jeunes joueurs issus d’un centre de formation',
    },
    {
      path: 'https://psychomot-media.s3.eu-west-3.amazonaws.com/Vers+une+perspective+d%E2%80%99evolution+professionnelle.pdf',
      author: 'Le Piver, K. (2014)',
      title:
        'Football et Psychomotricité : Vers une perspective d’évolution professionnelle',
    },
    {
      path: 'https://psychomot-media.s3.eu-west-3.amazonaws.com/L%E2%80%99avant-match.pdf',
      author: 'Chollet, L. (2021)',
      title:
        "L'avant-match — Football et Psychomotricité : Une seule et même équipe",
    },
    {
      path: 'https://psychomot-media.s3.eu-west-3.amazonaws.com/Psychomotricite+et+football+de+haut+niveau.pdf',
      author: 'Messe, G. (2017)',
      title: 'Psychomotricité et football de haut niveau',
    },
    {
      path: 'https://psychomot-media.s3.eu-west-3.amazonaws.com/Approche+de+l%E2%80%99imagerie+motrice+en+psychomotricite%CC%81+dans+la+recherche+de+performances+pour+footballeurs+de+haut+niveau.pdf',
      author: 'Messe, G. (2019)',
      title:
        'Approche de l’imagerie motrice en psychomotricité dans la recherche de performances pour footballeurs de haut niveau',
    },
  ],
  crossfit: [
    {
      path: 'https://psychomot-media.s3.eu-west-3.amazonaws.com/Le+CrossFit+peut-il+e%CC%82tre+une+me%CC%81diation+psychomotrice.pdf',
      author: 'Freret, M. (2021)',
      title:
        'Le CrossFit®, une marque, un sport mais pas seulement. Le CrossFit® peut-il être une médiation psychomotrice ?',
    },
  ],
};

export default sports;
