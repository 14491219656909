// Components
import Home from 'components/Home';
import Psycho from 'components/Psycho';
import Me from 'components/Me';
import Research from 'components/Research';
import Legal from 'components/Legal';
import Covid from 'components/Covid';
import MindMaps from 'components/MindMaps';
import Sport from 'components/Sport';

export type Item = {
  path: string;
  exact: boolean;
  title: string;
  color: string;
  component: () => JSX.Element;
};

const items: Item[] = [
  {
    path: '/',
    exact: true,
    title: 'accueil',
    color: 'rgb(255, 90, 80)',
    component: () => <Home color={'rgb(255, 90, 80)'} />,
  },
  {
    path: '/psychomotricite',
    exact: true,
    title: 'psychomotricite',
    color: 'rgb(255, 200, 0)',
    component: () => <Psycho color={'rgb(255, 200, 0)'} />,
  },
  {
    path: '/moi',
    exact: true,
    title: 'qui suis-je ?',
    color: 'rgb(0, 200, 90)',
    component: () => <Me color={'rgb(0, 200, 90)'} />,
  },
  {
    path: '/recherches',
    exact: true,
    title: 'mes recherches',
    color: 'rgb(0, 120, 255)',
    component: () => <Research color={'rgb(0, 120, 255)'} />,
  },
  {
    path: '/mentions',
    exact: true,
    title: 'legal',
    color: 'orange',
    component: () => <Legal color={'orange'} />,
  },
  {
    path: '/covid',
    exact: true,
    title: 'covid',
    color: 'purple',
    component: () => <Covid color={'purple'} />,
  },
  {
    path: '/cartes-mentales',
    exact: true,
    title: 'cartes mentales',
    color: 'gray',
    component: () => <MindMaps color={'gray'} />,
  },
  {
    path: '/sport&psychomot',
    exact: true,
    title: "sport & psychomot'",
    color: 'chocolate',
    component: () => <Sport color={'chocolate'} />,
  },
];

export default items;
