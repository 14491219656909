import styled from 'styled-components';

export const Section = styled.section`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 150px 50px 100px;

  h5 {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 30px;
  }

  h6 {
    font-size: 20px;
    font-weight: 500;
    margin-top: 50px;
    color: #444;
  }

  p {
    font-size: 14px;
    font-weight: 300;
    margin-top: 20px;
    text-align: left;
    max-width: 700px;
    color: #444;
    width: 100%;
  }

  @media (min-width: 768px) {
    padding-left: 70px;
    padding-right: 70px;
  }

  @media (min-width: 1000px) {
    max-width: 1100px;
    margin: auto;
  }
`;

export const Annuaire = styled.a`
  text-decoration: none;
  padding-bottom: 3px;
  color: #877edc;

  &:hover {
    border-bottom: 1px dashed #877edc;
  }
`;
