import { useSpring } from 'react-spring';

// Components
import Separator from 'components/Separator';

// Types
import type { MetadataInfo } from 'data/metadata';

// Styles
import * as S from './styles';

const Carousel = ({
  index,
  activeIndex,
  metadata,
  path,
  images,
  color,
}: {
  index: number;
  activeIndex: number;
  metadata: MetadataInfo;
  path: string;
  images: string[];
  color: string;
}) => {
  const active = index === activeIndex;

  const left = useSpring({
    opacity: active ? 1 : 0,
    transform: active ? 'translate3d(0%,0,0)' : 'translate3d(-20%,0,0)',
  });

  const right = useSpring({
    opacity: active ? 1 : 0,
    transform: active ? 'translate3d(0%,0,0)' : 'translate3d(20%,0,0)',
  });

  return (
    <S.Slide active={active}>
      <S.LeftContainer style={left}>
        <S.H1>{metadata.title}</S.H1>
        <Separator />
        {metadata.texts.map((text: string, index: number) => (
          <S.Text key={index}>{text}</S.Text>
        ))}
        {metadata.author && (
          <S.Author>
            <i>{metadata.author}</i>
          </S.Author>
        )}
        <S.More to={path}>
          <S.Span style={{ color }}>
            {metadata.button}
            <S.Icon icon='angle-right' size='1x' />
          </S.Span>
        </S.More>
      </S.LeftContainer>
      <S.RightContainer style={right}>
        <S.Picture>
          <source
            media='(min-width: 1200px)'
            type='image/jpeg'
            srcSet={images[2]}
          />
          <source
            media='(min-width: 1000px)'
            type='image/jpeg'
            srcSet={images[1]}
          />
          <S.Image src={images[0]} alt='Slide Cover' />
        </S.Picture>
      </S.RightContainer>
    </S.Slide>
  );
};

export default Carousel;
