import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';

interface Props {
  inModal: boolean;
}

export const Item = styled.li<Props>`
  height: ${(props) => props.inModal && '60px'};
  margin-left: ${(props) => !props.inModal && '40px'};
`;

export const Link = styled(NavLink)<Props>`
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
  color: #4a4242;
  position: relative;

  ${(props) =>
    props.inModal &&
    css`
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    `}
`;

export const Overlay = styled.span<Props>`
  ${(props) =>
    !props.inModal &&
    css`
      ${Item}:hover & {
        position: absolute;
        transform: translateY(-50%) translateX(-50%);
        left: 50%;
        width: 120%;
        height: 7px;
        top: 50%;
        z-index: -1;
        opacity: 0.25;
      }
    `}

  .selected > & {
    position: absolute;
    transform: translateY(-50%) translateX(-50%);
    left: 50%;
    width: 120%;
    height: 7px;
    top: 50%;
    z-index: -1;
    opacity: 0.25;
  }
`;
