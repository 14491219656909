export type MetadataInfo = {
  title: string;
  texts: string[];
  author?: string;
  button: string;
};

export type Metadata = {
  [key: string]: MetadataInfo;
};

const metadata: Metadata = {
  first: {
    title: 'PSYCHOMOTRICITE',
    texts: [
      'Le soin psychomoteur dans sa théorie et sa clinique intègre le psychisme et la motricité comme un tout indissociable au sein d’une approche qui se veut globale.',
      'La psychomotricité est une technique qui utilise le corps, l’espace et le temps dont l’objectif est de permettre à la personne d’expérimenter son corps et son environnement immédiat afin d’y agir de façon adaptée.',
    ],
    author: '- Delièvre & Staes',
    button: 'EN SAVOIR PLUS',
  },
  second: {
    title: 'QUI SUIS-JE ?',
    texts: [
      "Je suis psychomotricienne diplômée d'état depuis 2015. Je vis psychomotricité. J'apprends et découvre jour après jour en pratiquant, au travers d'échanges et de rencontres, en explorant, lisant. Je transmets. Mon métier, c'est ma passion. Ma vocation.",
    ],
    button: 'LIRE LA SUITE',
  },
  third: {
    title: 'MES RECHERCHES',
    texts: [
      'Au travers de mes différents cursus académiques et de mon expérience professionnelle, j’ai rédigé plusieurs mémoires et autres articles parus dans des revues spécialisées en psychomotricité.',
      'Pour les plus curieux qui aimeraient lire mes recherches, je les laisse en libre accès.',
    ],
    button: 'DECOUVRIR',
  },
};

export default metadata;
