import styled from 'styled-components';

export const Separator = styled.div`
  width: 100px;
  height: 5px;
  border-radius: 2.5px;
  background-color: #e8e8e8;
  margin-top: 20px;
  margin-bottom: 50px;
  align-self: center;

  @media (min-width: 768px) {
    width: 250px;
  }

  @media (min-width: 1000px) {
    align-self: flex-start;
  }
`;
