import { useEffect } from 'react';

// Components
import Slider from 'components/Slider';
import Footer from 'components/Footer';

// Data
import slides from 'data/slides';

// Styles
import * as S from './styles';

const Home = ({ color }: { color: string }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <S.Section>
      <Slider slides={slides} color={color} />
      <Footer color={color} />
    </S.Section>
  );
};

export default Home;
