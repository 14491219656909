import { useEffect } from 'react';

// Components
import Footer from 'components/Footer';
import Separator from 'components/Separator';

// Data
import mindmaps from 'data/mindmaps';

// Styles
import * as S from './styles';

const Covid = ({ color }: { color: string }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <S.Section>
      <S.Container>
        <h2>Cartes Mentales</h2>
        <Separator />
        <div style={{ maxWidth: '700px', width: '100%' }}>
          <p>
            Sur cet espace, vous trouverez mes différentes cartes mentales. Ces
            dernières ont pour objectif de favoriser la compréhension des
            notions associées et nécessaire au métier de Psychomotricien D.E.
          </p>
          <p>
            Merci de ne pas en altérer le contenu et de respecter ma propriété
            intellectuelle en laissant ma signature et en m'identifiant si vous
            les utilisez sur vos réseaux-sociaux.
          </p>
          <p>
            N'hésitez pas si vous avez des remarques ou questions à propos de
            leurs contenus.{' '}
          </p>
          <p>
            Par ailleurs, si vous souhaitez avoir plus d'informations, suivez le
            développement de mes cartes mentales sur Instagram.
          </p>
          <p>Laura, Allo Psychomot'</p>
          <p>
            Ci-joint le lien de mon
            <S.Link
              href='https://www.psychomotmaison.com/cartes-mentales-mindmap'
              rel='noopener noreferrer'
              target='_blank'>
              {' '}
              interview{' '}
            </S.Link>
            sur le mindmap fait par le site Psychomotmaison.com.
          </p>
          <p>
            Toutes les mindmaps ci-dessous sont sous la licence{' '}
            <S.Link
              href='https://creativecommons.org/licenses/by-nc-nd/4.0/'
              rel='noopener noreferrer'
              target='_blank'>
              {' '}
              CC-BY-NC-ND 2.0{' '}
            </S.Link>
          </p>
        </div>
        <S.Grid>
          {mindmaps.map((mindmap, i) => (
            <S.Anchor
              key={i}
              href={mindmap.url}
              target='_blank'
              rel='noopener noreferrer'>
              <S.Image src={mindmap.img} alt={mindmap.title} />
            </S.Anchor>
          ))}
        </S.Grid>
      </S.Container>
      <Footer color={color} />
    </S.Section>
  );
};

export default Covid;
