import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Credit = styled.div`
  align-items: center;
  justify-content: center;
  width: 260px;
  display: flex;
  flex-direction: column;
`;

export const Div = styled.div`
  margin-top: 50px;
  margin-bottom: 10px;

  @media (min-width: 768px) {
    margin-top: 20px;
    margin-bottom: 20px;
  }
`;

export const Legal = styled(Link)`
  font-weight: 400;
  font-size: 15px;
  color: white;
  padding-bottom: 2px;
  border-bottom: 1px solid #fff;
  text-decoration: none;
  cursor: pointer;
  margin: 0 10px;

  &:hover {
    color: hsla(0, 0%, 100%, 0.5);
    text-decoration: none;
    border-bottom: 1px solid hsla(0, 0%, 100%, 0.5);
  }
`;
