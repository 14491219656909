import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    border: 0;
  }

  html, body, #root {
    height: 100%;

    @media not all and (min-resolution:.001dpcm) {
      @media {
        height: auto;
      }
    }
  }

  body {
    font-family: 'Lato', sans-serif;
  }

  a {
    -webkit-tap-highlight-color: transparent;
  }
`;
