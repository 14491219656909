import { useEffect } from 'react';

// Components
import Footer from 'components/Footer';

// Styles
import * as S from './styles';

const Legal = ({ color }: { color: string }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <S.Section>
      <S.Container>
        <h5>MENTIONS LEGALES</h5>
        <p>Raison sociale: MADAME LAURA DIAS DA SILVA</p>
        <p>N° Siret: 823 056 171 00015</p>
        <p>N° Adeli: 60 96 0103 4</p>
        <p>Directeur de la publication : Laura Dias Da Silva</p>
        <h6>Cookies</h6>
        <p>
          Afin de répondre au mieux à vos besoins, ce site est susceptible
          d'avoir recours à la technologie des « cookies ».
        </p>
        <p>
          Le cookie est un petit fichier informatique envoyé par l'éditeur d'un
          site à son internaute, qui contient diverses informations relatives au
          passage de l'internaute sur le site (pages consultées, date et heure
          de la consultation). Il ne peut pas endommager les données présentes
          sur l’appareil (ordinateur, tablette, smartphone, etc.) de
          l'internaute.
        </p>
        <p>
          Vous pouvez configurer votre navigateur pour empêcher l'enregistrement
          de ces cookies sur votre appareil (ordinateur, tablette, smartphone,
          etc...), en désactivant cette fonction dans votre logiciel de
          navigation.
        </p>
        <h6>Propriété intellectuelle</h6>
        <p>
          La structure générale, les logiciels, textes, images, sons,
          savoir-faire, dessins, graphismes et tout autre élément composant le
          site et la manière dont ce dernier est agencé sont la propriété de
          Laura Dias Da Silva. Toute représentation ou reproduction, totale ou
          partielle de ces éléments, par quelque procédé que ce soit, sans
          l’autorisation expresse de Laura Dias Da Silva, est interdite et
          constituerait une contrefaçon au sens des articles L.335-2 et suivants
          du Code de la propriété intellectuelle. Il en est de même pour les
          bases de données figurant sur le site. De même, Laura Dias Da Silva
          est titulaire de l’ensemble des droits relatifs à ses marques et
          logos, qui sont déposés ; il est interdit de les reproduire ou de les
          représenter sur quelque support que ce soit et pour quelque finalité
          que ce soit, sans l’autorisation préalable et expresse de Laura Dias
          Da Silva.
        </p>
        <h6>Annuaire Santé</h6>
        <div style={{ marginTop: '20px' }}>
          <S.Annuaire
            href='http://www.annuairesante.com/psychomotricien/amblainville-60110'
            rel='noopener noreferrer'
            target='_blank'
            title='Psychomotriciens Amblainville'>
            Psychomotriciens à Amblainville
          </S.Annuaire>
        </div>
      </S.Container>
      <Footer color={color} />
    </S.Section>
  );
};

export default Legal;
