// Components
// import Reservation from 'components/Reservation';

// Styles
import * as S from './styles';

const Credit = () => {
  return (
    <S.Credit>
      {/* <Reservation color={color} /> */}
      <S.Div>
        <S.Legal to={'/covid'}>COVID</S.Legal>
        <S.Legal to={'/mentions'}>Mentions légales</S.Legal>
      </S.Div>
      <p>©2021 Tous droits réservés.</p>
    </S.Credit>
  );
};

export default Credit;
