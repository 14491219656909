import { useEffect } from 'react';

// Components
import Footer from 'components/Footer';
import Separator from 'components/Separator';

// Images
import interview from 'images/interview.png';

// Data
import documents from 'data/documents';
import articles from 'data/articles';

// Styles
import * as S from './styles';

const Research = ({ color }: { color: string }) => {
  const url =
    'https://www.isrp.fr/interview-de-laura-dias-da-silva-stress-post-traumatique/?utm_source=RS&utm_medium=portrait&utm_campaign=Laura_post_traumatisme&fbclid=IwAR0hvBESBOPCZv-uJ9NTWYzmZacrC9iF_esmErkfBtQK_j4qePZ4xFT272I#.XpSh2qszZ3a';

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <S.Section>
      <S.Container>
        <h2>MES RECHERCHES</h2>
        <Separator />
        <S.Div>
          <div style={{ marginBottom: '20px' }}>
            <S.Interview
              href={url}
              target='_blank'
              rel='noopener noreferrer'
              download>
              Mon interview sur le stress post-traumatique
            </S.Interview>
          </div>
          <S.Image src={interview} alt='Mon interview' />
          <S.Italic>Photographe: Jen Art</S.Italic>
        </S.Div>
        <h3>
          Prépublication d'articles pour la revue <i>Evolution Psychomotrice</i>
        </h3>
        <S.Documents>
          {articles.map((article, index) => (
            <S.Button
              key={index}
              href={article.path}
              target='_blank'
              rel='noopener noreferrer'
              download>
              <div className='research-container__doc--top'>
                <S.Doc />
                <S.Text>{article.author}</S.Text>
                <S.Text>{article.title}</S.Text>
              </div>
              <S.Span style={{ color }}>
                LIRE
                <S.Icon icon='angle-right' size='1x' />
              </S.Span>
            </S.Button>
          ))}
        </S.Documents>
        <h3>Mémoires</h3>
        <S.Documents>
          {documents.map((document, index) => (
            <S.Button
              key={index}
              href={document.path}
              target='_blank'
              rel='noopener noreferrer'
              download>
              <div className='research-container__doc--top'>
                <S.Doc />
                <S.Text>{document.author}</S.Text>
                <S.Text>{document.title}</S.Text>
              </div>
              <S.Span style={{ color }}>
                LIRE
                <S.Icon icon='angle-right' size='1x' />
              </S.Span>
            </S.Button>
          ))}
        </S.Documents>
      </S.Container>
      <Footer color={color} />
    </S.Section>
  );
};

export default Research;
