import styled from 'styled-components';

// Icons
import { ReactComponent as LogoIcon } from 'icons/logo.svg';
import { ReactComponent as BurgerIcon } from 'icons/burger.svg';

export const Header = styled.header`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 65px;
  position: fixed;
  overflow: hidden;
  left: 0;
  right: 0;
  padding: 0 20px;
  background-color: white;
  z-index: 42;
  max-width: 1300px;
  margin: auto;

  @media (min-width: 768px) {
    height: 80px;
    padding: 0 70px;
  }

  @media (min-width: 1200px) {
    padding: 0 150px;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const Logo = styled(LogoIcon)`
  width: 80px;

  @media (min-width: 768px) {
    width: 100px;
  }
`;

export const Title = styled.div`
  display: none;
  flex-direction: column;
  justify-content: center;
  margin-left: 20px;

  @media (min-width: 1200px) {
    display: flex;
  }
`;

export const H3 = styled.h3`
  font-weight: 600;
  font-size: 14px;
  color: #333333;
`;

export const P = styled.p`
  font-weight: 600;
  font-size: 12px;
`;

export const Burger = styled(BurgerIcon)`
  width: 30px;

  @media (min-width: 1000px) {
    display: none;
  }
`;

export const Items = styled.ul`
  display: none;
  list-style: none;

  @media (min-width: 1000px) {
    display: flex;
  }
`;
