export type Document = {
  path: string;
  author: string;
  title: string;
};

const documents: Document[] = [
  {
    path: 'https://s3.eu-west-3.amazonaws.com/psychomot-media/Memoire+D.E.+de+Psychomotricite%CC%81.pdf',
    author: 'Dias Da Silva, L. (2015)',
    title:
      "Quand la douleur s'installe au sein de la conscience corporelle. Approche psychomotrice et médiatrice du sujet douloureux chronique",
  },
  {
    path: 'https://s3.eu-west-3.amazonaws.com/psychomot-media/Memoire+Master+Internationale+de+Psychomotricite%CC%81.pdf',
    author: 'Dias Da Silva, L. (2017)',
    title:
      'La pénibilité du travail de sapeur-pompier. Etude du tonus musculaire de fond par un protocole de relaxation psychosomatique. Memoire Master International de Psychomotricité',
  },
  {
    path: 'https://s3.eu-west-3.amazonaws.com/psychomot-media/Memoire+Master+2+STAPS+PNMEPS.pdf',
    author: 'Dias Da Silva, L. (2018)',
    title:
      "Effets des techniques hypnotiques «de suggestion» sur la performance et la perception d'un effort douloureux au sein d'une caserne",
  },
];

export default documents;
