import { Route, Link } from 'react-router-dom';

// Components
// import Reservation from 'components/Reservation';

// Types
import type { Item } from 'data/items';

// Styles
import * as S from './styles';

const Header = ({
  items,
  links,
  showModal,
}: {
  items: Item[];
  links: (JSX.Element | null)[];
  showModal: (event?: React.SyntheticEvent<HTMLAnchorElement>) => void;
}) => {
  return (
    <S.Header>
      <S.Container>
        <Link to='/' aria-label='Accueil'>
          <S.Logo />
        </Link>
        <S.Title>
          <S.H3>Laura Dias Da Silva</S.H3>
          {items.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              exact={route.exact}
              component={() => (
                <S.P style={{ color: route.color }}>
                  Psychomotricienne D.E/T.E
                </S.P>
              )}
            />
          ))}
        </S.Title>
      </S.Container>
      <S.Container>
        {/* {items.map((route, index) => (
          <Route
            key={index}
            path={route.path}
            exact={route.exact}
            component={() => <Reservation color={route.color} inHeader />}
          />
        ))} */}
        <S.Items>{links}</S.Items>
        <a href='void(0)' onClick={showModal} aria-label='Ouverture Menu'>
          <S.Burger />
        </a>
      </S.Container>
    </S.Header>
  );
};

export default Header;
