import { useState, useEffect, useRef, useCallback } from 'react';

// Components
import Carousel from 'components/Carousel';
import Indicator from 'components/Indicator';
import { LeftArrow, RightArrow } from 'components/Arrow';

// Data
import metadata from 'data/metadata';

// Types
import type { Slide } from 'data/slides';

// Styles
import * as S from './styles';

const Slider = ({ slides, color }: { slides: any; color: string }) => {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const interval = useRef<any>();

  const goToSlide = (
    event: React.MouseEvent<HTMLAnchorElement>,
    index: number,
  ) => {
    event.preventDefault();

    setActiveIndex(index);
  };

  const goToPrevSlide = useCallback(
    (event?: React.MouseEvent<HTMLAnchorElement>) => {
      if (event) {
        event.preventDefault();
      }

      let index = activeIndex;
      let slidesLength = slides.length;

      if (index === 0) {
        index = slidesLength;
      }

      --index;

      setActiveIndex(index);
    },
    [activeIndex, slides],
  );

  const goToNextSlide = useCallback(
    (event?: React.MouseEvent<HTMLAnchorElement>) => {
      if (event) {
        event.preventDefault();
      }

      let index = activeIndex;
      let slidesLength = slides.length;

      if (index === slidesLength - 1) {
        index = -1;
      }

      ++index;

      setActiveIndex(index);
    },
    [activeIndex, slides],
  );

  useEffect(() => {
    interval.current = setInterval(() => {
      goToNextSlide();
    }, 5000);

    return () => clearInterval(interval.current);
  }, [goToNextSlide]);

  return (
    <S.Carousel>
      <S.Container>
        <S.Slides>
          {slides.map((slide: Slide, index: number) => (
            <Carousel
              key={index}
              index={index}
              activeIndex={activeIndex}
              metadata={metadata[slide.order]}
              path={slide.path}
              images={slide.images}
              color={color}
            />
          ))}
        </S.Slides>
        <S.Pagination>
          <LeftArrow
            onClick={(event: React.MouseEvent<HTMLAnchorElement>) =>
              goToPrevSlide(event)
            }
          />
          <S.Indicators>
            {slides.map((_: Slide, index: number) => (
              <Indicator
                key={index}
                index={index}
                activeIndex={activeIndex}
                onClick={(event: React.MouseEvent<HTMLAnchorElement>) =>
                  goToSlide(event, index)
                }
              />
            ))}
          </S.Indicators>
          <RightArrow
            onClick={(event: React.MouseEvent<HTMLAnchorElement>) =>
              goToNextSlide(event)
            }
          />
        </S.Pagination>
      </S.Container>
    </S.Carousel>
  );
};

export default Slider;
