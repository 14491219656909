import styled from 'styled-components';

export const Section = styled.section`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 150px 50px 100px;

  h2 {
    font-size: 30px;
    font-weight: 600;
    align-self: center;
  }

  p {
    font-size: 20px;
    font-weight: 300;
    margin-bottom: 20px;
    color: #444;
    line-height: 25px;
  }

  @media (min-width: 768px) {
    padding-left: 70px;
    padding-right: 70px;

    h2 {
      font-size: 40px;
    }
  }

  @media (min-width: 1000px) {
    max-width: 1100px;
    margin: auto;

    h2 {
      font-size: 50px;
      align-self: flex-start;
    }

    p {
      align-self: flex-start;
    }
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: auto;
  column-gap: 20px;
  row-gap: 20px;
  margin-top: 50px;
  justify-content: center;

  @media (min-width: 768px) {
    grid-template-columns: 300px 300px;
  }

  @media (min-width: 1024px) {
    grid-template-columns: 250px 250px 250px;
  }
`;

export const Anchor = styled.a`
  border-style: solid;
  border-width: 2px;
  border-color: white;
  border-radius: 4px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  aspect-ratio: 1;
  max-width: 300px;
`;

export const Image = styled.img`
  width: 100%;
`;

export const Link = styled.a`
  text-decoration: none;
  padding-bottom: 3px;
  color: #877edc;
  align-self: center;
  margin-bottom: 20px;
  border-bottom: 1px solid transparent;

  &:hover {
    border-bottom: 1px dashed #877edc;
  }

  @media (min-width: 1000px) {
    align-self: flex-start;
  }
`;
