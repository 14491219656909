import ReactDOM from 'react-dom';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faAngleRight, faAngleLeft } from '@fortawesome/free-solid-svg-icons';

// Components - Service Worker
import Root from 'components/Root';
import * as serviceWorker from './serviceWorker';

library.add(faAngleRight, faAngleLeft);

ReactDOM.render(<Root />, document.getElementById('root'));

serviceWorker.register();
