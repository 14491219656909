import styled from 'styled-components';

export const Footer = styled.footer`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  padding: 150px 0 100px;

  p {
    font-weight: 400;
    font-size: 15px;
    text-align: left;
    color: white;
  }

  h4 {
    font-weight: 400;
    font-size: 16px;
    color: white;
    margin-bottom: 10px;
  }
`;

export const Background = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
  max-width: 100vw;
  overflow: hidden;
`;

export const Color = styled.div`
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
`;

export const Path = styled.div`
  position: absolute;
  left: 0px;
  width: 100vw;
  min-width: 800px;
  transform: rotate(180deg);
  bottom: auto;
  top: -6px;
`;

export const Foreground = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-right: 100px;
    padding-left: 100px;
  }

  @media (min-width: 1000px) {
    padding: 0;
    width: 80%;
    max-width: 800px;
    margin: auto;
  }
`;
