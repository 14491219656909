import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Icons
import { ReactComponent as DocIcon } from 'icons/doc.svg';

export const Section = styled.section`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 150px 50px 100px;

  h2 {
    font-size: 30px;
    font-weight: 600;
    align-self: center;
  }

  @media (min-width: 768px) {
    padding-left: 70px;
    padding-right: 70px;

    h2 {
      font-size: 40px;
    }
  }

  @media (min-width: 1000px) {
    max-width: 1100px;
    margin: auto;

    h2 {
      font-size: 50px;
      align-self: flex-start;
    }

    p {
      align-self: flex-start;
    }
  }
`;

export const Div = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;

  @media (min-width: 1000px) {
    align-items: flex-start;
  }
`;

export const Interview = styled.a`
  text-decoration: none;
  padding-bottom: 3px;
  color: #877edc;

  &:hover {
    border-bottom: 1px dashed #877edc;
  }
`;

export const Image = styled.img`
  width: 80%;
  max-width: 500px;
  border-style: solid;
  border-width: 2px;
  border-color: white;
  border-radius: 4px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.3);
  overflow: hidden;
`;

export const Italic = styled.i`
  margin-top: 10px;
  font-size: 14px;
  font-weight: 300;
  color: #444;
`;

export const Documents = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  margin-left: -10px;
  margin-top: 12px;
  margin-bottom: 12px;

  @media (min-width: 768px) {
    justify-content: flex-start;
  }
`;

export const Button = styled.a`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  max-width: 200px;
  padding: 20px 10px 20px 10px;
  cursor: pointer;
  border-style: solid;
  border-radius: 8px;
  border-width: 0.5px;
  border-color: rgba(0, 0, 0, 0.2);
  text-decoration: none;
  margin: 10px;
`;

export const Doc = styled(DocIcon)`
  margin-bottom: 20px;
`;

export const Text = styled.p`
  font-size: 15px;
  font-weight: 400;
  color: #4a4242;
  margin-bottom: 16px;
`;

export const Span = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 13px;
  font-weight: 600;
`;

export const Icon = styled(FontAwesomeIcon)`
  margin-left: 5px;
  margin-top: 1px;
`;
