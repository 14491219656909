import styled from 'styled-components';

export const Section = styled.section`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 150px 50px 100px;

  h2 {
    font-size: 30px;
    font-weight: 600;
    align-self: center;
  }

  p {
    font-size: 20px;
    font-style: italic;
    font-weight: 300;
    margin-bottom: 20px;
    color: #444;
    line-height: 25px;
  }

  ul {
    list-style: none;
  }

  li {
    font-size: 16px;
    font-weight: 300;
    margin-bottom: 20px;
    color: #444;
  }

  @media (min-width: 768px) {
    padding-left: 70px;
    padding-right: 70px;

    h2 {
      font-size: 40px;
    }
  }

  @media (min-width: 1000px) {
    max-width: 1100px;
    margin: auto;

    h2 {
      font-size: 50px;
      align-self: flex-start;
    }

    p {
      align-self: flex-start;
    }
  }
`;

export const Div = styled.div`
  margin-bottom: 20px;
  text-align: center;

  @media (min-width: 1000px) {
    text-align: left;
  }
`;

export const Resume = styled.a`
  text-decoration: none;
  padding-bottom: 3px;
  color: #877edc;

  &:hover {
    border-bottom: 1px dashed #877edc;
  }
`;

export const First = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 50px;

  @media (min-width: 1000px) {
    flex-direction: row;
    align-items: flex-start;
  }
`;

export const Second = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 50px;

  @media (min-width: 1000px) {
    flex-direction: row;
    align-items: flex-start;
  }
`;

export const Picture = styled.picture`
  width: 300px;
  max-width: 300px;
  align-self: center;

  @media (min-width: 768px) {
    width: 600px;
    max-width: 600px;
  }
`;

export const Citation = styled.p`
  font-size: 24px !important;
  font-style: italic;
  color: #555 !important;
  line-height: 1.5em !important;
`;

export const Link = styled.a`
  text-decoration: none;
  padding-bottom: 3px;
  color: #877edc;

  &:hover {
    border-bottom: 1px dashed #877edc;
  }
`;

export const Fondations = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  width: 100%;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

export const Fondation = styled.img`
  width: 100%;
  margin: 20px 0;

  @media (min-width: 768px) {
    width: 50%;
    margin: 0 10px;
  }
`;

export const Player = styled.video`
  width: 100% !important;
  border-style: solid;
  border-width: 2px;
  border-color: white;
  border-radius: 4px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.3);
  margin-bottom: 50px;

  & > div {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
`;
