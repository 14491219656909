import styled from 'styled-components';

export const Carousel = styled.div`
  padding: 16vh 0 100px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-left: 50px;
  padding-right: 50px;

  @media (min-width: 768px) {
    padding-left: 70px;
    padding-right: 70px;
  }

  @media (min-width: 1000px) {
    height: 500px;
    max-width: 1100px;
    margin: auto;
  }
`;

export const Slides = styled.ul`
  list-style: none;

  @media (min-width: 1000px) {
    width: 100%;
  }
`;

export const Pagination = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 50px;
`;

export const Indicators = styled.ul`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  list-style: none;

  li {
    &:nth-of-type(n + 2) {
      margin-left: 9px;
    }
  }
`;
