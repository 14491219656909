// Images
import psychomot from 'images/mindmap_psychomotricien.png';
import lateralite from 'images/mindmap_lateralite.png';
import bilan from 'images/mindmap_bilan_psychomot.png';
import neuro from 'images/mindmap_neuro_visio_moteur.png';
import parkinson from 'images/mindmap_parkinson.png';
import tonus from 'images/mindmap_tonus.png';
import tsla from 'images/mindmap_tsla.png';
import alzheimer from 'images/mindmap_alzheimer.png';
import espace from 'images/mindmap_espace.png';
import douleur from 'images/mindmap_douleur.png';
import fonctions from 'images/mindmap_fonctions_executives.png';
import grapho from 'images/mindmap_graphomotricite.jpg';
import emotions from 'images/mindmap_emotions.png';
import hyperactivite from 'images/mindmap_hyperactivite_ou_instabilite.png';
import equilibre from 'images/mindmap_equilibre.png';
import tdi from 'images/mindmap_tdi.png';
import sdpm from 'images/mindmap_sdpm.png';
import tsa from 'images/mindmap_tsa.jpg';
import empathie from 'images/mindmap_empathie.png';

export type MindMap = {
  title: string;
  url: string;
  img: string;
};

const mindmaps: Array<MindMap> = [
  {
    title: 'Psychomotricien',
    url: 'https://psychomot-media.s3.eu-west-3.amazonaws.com/MindMap+Psychomotricien.pdf',
    img: psychomot,
  },
  {
    title: 'Lateralite',
    url: 'https://psychomot-media.s3.eu-west-3.amazonaws.com/MindMap+Lateralite.pdf',
    img: lateralite,
  },
  {
    title: 'Bilan Psychomoteur',
    url: 'https://psychomot-media.s3.eu-west-3.amazonaws.com/MindMap+Bilan+Psychomoteur.pdf',
    img: bilan,
  },
  {
    title: 'Systeme Neuro-Visio-Moteur',
    url: 'https://psychomot-media.s3.eu-west-3.amazonaws.com/MindMap+Neuro-Visuo-Moteur.pdf',
    img: neuro,
  },
  {
    title: 'Parkinson',
    url: 'https://psychomot-media.s3.eu-west-3.amazonaws.com/MindMap+Parkinson.pdf',
    img: parkinson,
  },
  {
    title: 'Tonus',
    url: 'https://psychomot-media.s3.eu-west-3.amazonaws.com/MindMap+Tonus.pdf',
    img: tonus,
  },
  {
    title: 'Espace',
    url: 'https://psychomot-media.s3.eu-west-3.amazonaws.com/MindMap+Espace.pdf',
    img: espace,
  },
  {
    title: "Maladie d'Alzheimer",
    url: 'https://psychomot-media.s3.eu-west-3.amazonaws.com/MindMap+Alzheimer.pdf',
    img: alzheimer,
  },
  {
    title: 'Troubles Spécifiques du Langage et des Apprentissages (TSLA)',
    url: 'https://psychomot-media.s3.eu-west-3.amazonaws.com/MindMap+TSLA.pdf',
    img: tsla,
  },
  {
    title: 'Douleur',
    url: 'https://psychomot-media.s3.eu-west-3.amazonaws.com/MindMap+Douleur.pdf',
    img: douleur,
  },
  {
    title: 'Fonctions Exécutives',
    url: 'https://psychomot-media.s3.eu-west-3.amazonaws.com/MindMap+Fonctions+Executives.pdf',
    img: fonctions,
  },
  {
    title: 'Graphomotricité',
    url: 'https://psychomot-media.s3.eu-west-3.amazonaws.com/MindMap+Graphomotricite.pdf',
    img: grapho,
  },
  {
    title: 'Emotions',
    url: 'https://psychomot-media.s3.eu-west-3.amazonaws.com/MindMap+Emotions.pdf',
    img: emotions,
  },
  {
    title: 'Hyperactivité ou instabilité psychomotrice',
    url: 'https://psychomot-media.s3.eu-west-3.amazonaws.com/MindMap+Hyperactivite+ou+Instabilite+Psychomotrice.pdf',
    img: hyperactivite,
  },
  {
    title: 'Equilibre',
    url: 'https://psychomot-media.s3.eu-west-3.amazonaws.com/MindMap+Equilibre.pdf',
    img: equilibre,
  },
  {
    title: 'Trouble du Développement Intellectuel',
    url: 'https://psychomot-media.s3.eu-west-3.amazonaws.com/MindMap+TDI.pdf',
    img: tdi,
  },
  {
    title: 'Syndrome de Désadaptation Psychomotrice (SDPM)',
    url: 'https://psychomot-media.s3.eu-west-3.amazonaws.com/MindMap+SDPM.pdf',
    img: sdpm,
  },
  {
    title: "Trouble du Spectre de l'Autisme (TSA)",
    url: 'https://psychomot-media.s3.eu-west-3.amazonaws.com/MindMap+TSA.pdf',
    img: tsa,
  },
  {
    title: 'Empathie',
    url: 'https://psychomot-media.s3.eu-west-3.amazonaws.com/MindMap+Empathie.pdf',
    img: empathie,
  },
];

export default mindmaps;
