export type Article = {
  path: string;
  author: string;
  title: string;
};

const articles: Article[] = [
  {
    path: 'https://psychomot-media.s3.eu-west-3.amazonaws.com/Approche+psychomotrice+et+transdisciplinaire+de+la+douleur.pdf',
    author: 'Dias Da Silva, L. (2016)',
    title:
      "Approche psychomotrice et trandisciplinaire de la douleur chez l'adulte",
  },
  {
    path: 'https://psychomot-media.s3.eu-west-3.amazonaws.com/La+penibilite+du+travail+de+sapeur-pompier.pdf',
    author: 'Dias Da Silva, L. (2017)',
    title:
      'La pénibilité du travail de sapeur-pompier. Etude du tonus musculaire de fond par un protocole de relaxation psychosomatique',
  },
];

export default articles;
