import styled from 'styled-components';
import YouTubePlayer from 'react-player';

// Icons
import { ReactComponent as TriniteIcon } from 'icons/trinite.svg';

export const Section = styled.section`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 150px 50px 100px;

  h2 {
    font-size: 30px;
    font-weight: 600;
    align-self: center;
  }

  h3 {
    font-size: 24px;
    font-weight: 600;
    text-align: center;
    color: #444;
    margin-bottom: 50px;
  }

  p {
    font-size: 16px;
    font-weight: 300;
    margin-bottom: 20px;
    color: #444;
    line-height: 25px;
  }

  b {
    font-size: 18px;
    margin-bottom: 50px;
    color: #444;
    line-height: 25px;
  }

  i {
    font-size: 14px;
    font-weight: 300;
    color: #444;
    line-height: 25px;
  }

  @media (min-width: 768px) {
    padding-left: 70px;
    padding-right: 70px;

    h2 {
      font-size: 40px;
    }
  }

  @media (min-width: 1000px) {
    max-width: 1100px;
    margin: auto;

    h2 {
      font-size: 50px;
      align-self: flex-start;
    }

    p,
    b {
      align-self: flex-start;
    }
  }
`;

export const First = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 50px;

  @media (min-width: 1000px) {
    flex-direction: row;
    align-items: flex-start;
  }
`;

export const Trinite = styled(TriniteIcon)`
  width: 260px;
  margin-top: 30px;

  @media (min-width: 1000px) {
    margin-left: 100px;
    margin-top: unset;
  }
`;

export const Second = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 50px;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: 1000px) {
    max-width: none;
  }
`;

export const Player = styled(YouTubePlayer)`
  width: 100% !important;
  height: 0 !important;
  padding-bottom: 56.25%;
  position: relative;
  border-style: solid;
  border-width: 2px;
  border-color: white;
  border-radius: 4px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  margin-bottom: 20px;

  & > div {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
`;

export const Third = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 50px;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: 1000px) {
    max-width: none;
  }
`;

export const Fourth = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  @media (min-width: 1000px) {
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

export const Image = styled.img`
  width: 100%;
  max-width: 350px;
  margin-bottom: 20px;
  border-style: solid;
  border-width: 2px;
  border-color: white;
  border-radius: 4px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.3);

  @media (min-width: 1000px) {
    margin-right: 10px;
    margin-left: 10px;
  }
`;
