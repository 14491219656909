import styled from 'styled-components';

const Arrow = styled.a`
  color: #111;
  cursor: pointer;
  opacity: 0.75;
  transition: opacity 0.15s cubic-bezier(0.4, 0, 1, 1);

  &:focus {
    outline: 0;
  }

  &:hover {
    opacity: 0.5;
  }
`;

export const LeftArrow = styled(Arrow)`
  margin-right: 30px;
`;

export const RightArrow = styled(Arrow)`
  margin-left: 30px;
`;
